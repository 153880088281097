<template>
  <div class="movement">
    <Crumbs></Crumbs>
    <el-row class="movement-top">
      <div class="movement-top-left">
        <span class="day">日期选择：</span>
        <el-date-picker
          v-model="movementInfo.date"
          type="month"
          value-format="timestamp"
          placeholder="选择日期"
          @change="getData"
          :clearable="false"
        >
        </el-date-picker>
      </div>
      <div class="movement-top-right">
        <el-button class="movement-top-btn" size="small" type="primary" @click="handle_export"
          >导出</el-button
        >
      </div>
    </el-row>
    <el-row class="movement-btm">
      <el-table
        :data="tableData"
        :loading="loading"
        border
        style="width: 100%"
        :span-method="objectSpanMethod"
        class="aa"
      >
        <el-table-column align="center" label="" width="">人力资源异动分析</el-table-column>
        <el-table-column prop="department" align="center" label="部门" width="" />
        <el-table-column label="上月末人数" width="" align="center" prop="syrs" />
        <el-table-column label="本月新增人员">
          <el-table-column label="社会招聘" prop="shzp" align="center" />
          <el-table-column label="内推" prop="ntzp" align="center" />
          <el-table-column label="其他" prop="qtzp" align="center" />
        </el-table-column>
        <el-table-column label="本月减少人员">
          <el-table-column label="主动离职" prop="zdlz" align="center" />
          <el-table-column label="被动离职" prop="bdlz" align="center" />
          <el-table-column label="其他" prop="qtlz" align="center" />
        </el-table-column>
        <el-table-column label="月末统计">
          <el-table-column label="月末人数" prop="ymrs" align="center" />
          <el-table-column label="较上月增加人数" prop="byxz" align="center" />
        </el-table-column>
        <template v-if="tableData">
          <el-table-column
            label="月离职率"
            :formatter="batteryformatter"
            prop="quit"
            align="center"
          />
        </template>
      </el-table>
    </el-row>
  </div>
</template>

<script>
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      movementInfo: {
        date: this.monthStartTimestamp(),
        pageNow: 1,
        pageSize: 50,
      },
      tableData: [],
      loading: false,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    //获得本月开始时间戳
    monthStartTimestamp() {
      let now = new Date()
      let nowYear = now.getFullYear() //当前年
      let nowMonth = now.getMonth() //当前月
      let firstDay = new Date(nowYear, nowMonth, 1).getTime() //本月开始时间戳
      return firstDay
    },

    // 月离职率加百分号展示
    batteryformatter(row) {
      return row.quit + '%'
    },

    //控制表格某元素行数
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 99,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      } else if (columnIndex === 11) {
        if (rowIndex === 0) {
          return {
            rowspan: 99,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    },

    // 获取数据
    async getData() {
      this.loading = true
      const res = await this.$api.user.getPersonnelChanges(this.movementInfo)
      this.reformData(res.data)
      let numAll1 = 0
      let numAll2 = 0
      res.data.forEach(v => {
        numAll1 += v.bdlz
        numAll1 += v.qtlz
        numAll1 += v.zdlz
        numAll2 += v.syrs
      })
      this.tableData = res.data
      this.tableData.forEach(v => {
        v.quit = Number(this.seekNum(numAll1, numAll2))
      })
      this.loading = false
    },
    reformData(list) {
      let bdlzAll = 0
      let byxzAll = 0
      let lzzsAll = 0
      let ntzpAll = 0
      let qtlzAll = 0
      let qtzpAll = 0
      let quitAll = 0
      let shzpAll = 0
      let syrsAll = 0
      let ymrsAll = 0
      let zdlzAll = 0
      list.forEach(v => {
        bdlzAll += v.bdlz
        byxzAll += v.byxz
        lzzsAll += v.lzzs
        ntzpAll += v.ntzp
        qtlzAll += v.qtlz
        qtzpAll += v.qtzp
        quitAll += v.quit
        shzpAll += v.shzp
        syrsAll += v.syrs
        ymrsAll += v.ymrs
        zdlzAll += v.zdlz
      })
      let Obj = {
        department: '合计',
        bdlz: bdlzAll,
        byxz: byxzAll,
        lzzs: lzzsAll,
        ntzp: ntzpAll,
        qtlz: qtlzAll,
        qtzp: qtzpAll,
        quit: quitAll,
        shzp: shzpAll,
        syrs: syrsAll,
        ymrs: ymrsAll,
        zdlz: zdlzAll,
      }
      list.push(Obj)
    },

    // 算月离职率
    seekNum(a, b) {
      if (b == 0) return 0
      let num = 0
      num = ((a / b) * 100).toFixed(2)
      return num
    },

    // 导出
    handle_export() {
      this.$api.user.exportPersonnelChanges().then(res => {
        const fileName = `人员异动分析.xlsx`
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, fileName)
        } else {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          URL.revokeObjectURL(link.href)
          this.$message({
            message: '导出成功!',
            type: 'success',
          })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.movement {
  padding: 10px;

  .movement-top {
    .movement-top-left {
      margin: 5px;
      height: 40px;

      .day {
        font-size: 20px;
        margin-right: 20px;
      }
    }

    .movement-top-right {
      position: relative;

      .movement-top-btn {
        position: absolute;
        right: 8px;
        top: -35px;
        width: 90px;
      }
    }
  }

  .movement-btm {
    margin-top: 20px;
  }
}

/deep/ .el-table thead.is-group th.el-table__cell {
  background: #dce6f1;
}

/deep/.el-table .has-gutter th {
  color: black;
}

/deep/ .el-table {
  border: 1px solid #080808;
  border-top: none;
  color: #080808;
}

/deep/.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf,
/deep/.el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-top: 1px solid #080808;
  border-right: 1px solid #080808;
}

/deep/ thead.is-group tr:first-of-type th.el-table__cell:first-of-type {
  background-color: #fde9d9;
  border-bottom: 1px solid #fde9d9;
}

/deep/.el-table__row:first-of-type td:first-of-type,
/deep/.el-table__row:first-of-type td:first-of-type:hover > td.el-table__cell {
  background-color: #fde9d9 !important;
  border-top: 1px solid #fde9d9;
}
</style>
<style>
.el-month-table td.today .cell {
  font-weight: 500;
  color: #606266;
}
</style>
